exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-catalog-accessories-tsx": () => import("./../../../src/pages/catalog/accessories.tsx" /* webpackChunkName: "component---src-pages-catalog-accessories-tsx" */),
  "component---src-pages-catalog-massive-tsx": () => import("./../../../src/pages/catalog/massive.tsx" /* webpackChunkName: "component---src-pages-catalog-massive-tsx" */),
  "component---src-pages-catalog-mdf-tsx": () => import("./../../../src/pages/catalog/mdf.tsx" /* webpackChunkName: "component---src-pages-catalog-mdf-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */)
}

